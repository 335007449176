import React from 'react'

function About() {
    return (
        <div id="about" className=" py-5 bg-color-odd " data-aos="slide-up" data-aos-once="true" >
            <div className="container " >
                <div className="row d-flex justify-content-center text-center">
                    <div className="col-md-8 ">
                        <div className=" px-2 pb-5">
                            <h3 className="section-header"><span className="section-number"> </span><span className="underline-header">About Us</span></h3>
                        </div>
                        <div className="px-2  about-content">
               <p>
                   <span className="highlight-text">Telnet Engineering Company Limited</span> was established and incorporated under Tanzanian Companies Act 2002 on 20th April, 2015 under certificate of Incorporation No: 116714
                   and it is a limited liability company, shared and directed by a group of well Experienced Engineers,
                   under whom contracts were concluded successfully in a wide range of diversified fields such as
                   Telecommunication, ICT and Integrated Security systems solutions to our esteemed customers.
               </p>
                            <p>
                              Telnet Engineering Company Limited is registered with <span className="highlight-text">Contractors Registration Board (CRB)</span> with registration number: <span className="highlight-text">SPE3/690/09/2016</span> as <span className="highlight-text">SPECIALIST ELECTRICAL CONTRACTOR CLASS THREE (3)</span>
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
