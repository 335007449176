import React from 'react'
function Products() {

    const telnet=[
        {
            "client" : "OFISI YA WAKILI MKUU WA SERIKALI",
            "work":"Supply and installation of Biometric Time and Attendance System",
            "year":"2019"
        },
        {
            "client" : "JULIUS INTERNATIONAL AIRPORT (JNIA)",
            "work":"Supply and Installation of Telephone / PABX Expansion Cards",
            "year":"2018"
        } ,    {
            "client" : "TANZANIA BREWERIES LIMITED",
            "work":"Installations of Local Area network",
            "year":"2018"
        },
        {
            "client" : "KARIAKOO MARKETS CORPORATION (KMC)",
            "work":"Supply, Installation and Commissioning of IP Based CCTV Security systems.",
            "year":"2018"
        },
        {
            "client" : "JE CONSTRUCTION COMPANY LIMITEDJE CONSTRUCTION COMPANY LIMITED",
            "work":"Supply, installation and commissioning of Local Area Network (LAN), VoIP and IP Based CCTV Security system",
            "year":"2018"
        },
        {
            "client" : "TANZANIA EDUCATION AUTHORITY (TEA)",
            "work":"Supply, installation and Configurations of Biometric Attendance systems and Wireless Network",
            "year":"2018"
        }   , {
            "client" : "Karume Institute of Science and Technology Zanzibar",
            "work":"Installation of Local Area Network (LAN) & Telephony System and Fire Alarm System",
            "year":"2018"
        }  ,  {
            "client" : "TANZANIA TELECOMUNICATION COMPANY LIMITED (TTCL)",
            "work":"Supply of CISCO routers with IP Security bundle",
            "year":"2018"
        }    , {
            "client" : "TANZANIA BUREAU OF STANDARDS (TBS)",
            "work":"Installations of IP CCTV Security Systems",
            "year":"2017"
        }
    ];

    return (
        <div id="products">
            <div className="container separator py-5">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="row ">
                            <div className="col-md-12 pb-5">
                                <h3 className="text-center section-header"><span
                                    className="section-number"> </span><span
                                    className="underline-header">Portfolio</span></h3>
                            </div>
                            {/* Tabular View of Products */}
                            <div className="col-md-12">
                                <div className="row pb-5">
                                        {telnet.map((telnetWorks)=>
                                           <div className="col-md-4 p-3 d-flex" data-aos="slide-up" data-aos-once="true">
                                              <div className="telnet-works d-flex flex-column px-4 py-3  ">
                                                  <p className="client">{telnetWorks.year} - {telnetWorks.client}</p>
                                                  <p className="work py-2"><span>&#187;  </span> {telnetWorks.work}</p>
                                                  {/*<p className="year">Year: {telnetWorks.year}</p>*/}
                                              </div>
                                           </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products
