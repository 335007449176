import React from 'react'
function Footer() {
    return (
<div className="pt-5" style={{background:"var(--light-bg)"}}>
    <div id="contact">
        <div className="container   ">
            <div className="row d-flex justify-content-center  px-2">
                <div className="col-md-8 contacts  contact-info py-5 line-separator footer-content">
                    <div className="row  underline-footer-bottom">
                        <div className="col-12 py-5 px-2">
                            <h3 className="section-header">
                                <span className="section-number"></span>
                                <span className="underline-header">
                                    Contact Us</span>
                            </h3>
                        </div>
                        <div className="col-12 px-2   ">
                       <address>
                              <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                   <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
                                  <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
                               </svg>
                          </span>
                           <a href="https://goo.gl/maps/xbSN8e1gWJiqkQay5">Office: IBACON House, 2nd Floor, Room No. 129, Kinondoni B,  Ikungi Street, Along Kawawa RD, Near Kinondoni Hospital,
                               P.O. Box 7544, Dar es Salaam, Tanzania </a>
                       </address>
                        </div>
                        <div className="col-12 px-2 ">
                            <address>
                                    <span className="footer-icon-bg">
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                                        </svg>
                                    </span>
                                <a href="mailto:info@telnet.co.tz"><span className="footer-text">info@telnet.co.tz</span></a>
                            </address>
                        </div>
                        <div className="col-12 px-2 footer-content">
                            <address>
                                    <span className="footer-icon-bg">
                                       <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                          <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                       </svg>
                                    </span>
                                        <a href="tel:+255655104625">
                                             <span className="footer-text">+255 655-104-625</span>
                                        </a>
                            </address>
                        </div>
                        <div className="px-2 footer-content">
                            <address>
                                    <span className="footer-icon-bg">
                                       <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                           <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>

                                       </svg> </span>
                                <a href="tel:+255222462050"><span className="footer-text">+255 22 246 2050</span></a>
                            </address>
                        </div>
                    </div>
                    <div className="row pt-5">
                      <div className="col-12 p-0">
                          <p>
                              For more information download our profile book
                          </p>
                      </div>
                        <div className="col-12 p-0">
                            <a href={'pdf/telnet_profile_book.pdf'} className="download" download>Profile</a>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row d-flex justify-content-center  py-5 ">
                <div className="col-md-6  py-4 footer-content">
                    <div className="text-center pb-3">
                        <svg id="Layer_1" className="logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                             height="2rem" viewBox="0 0 46.86 39.24">
                            <path className="cls-1" d="M32.4,28.38l23,9.86L32,51.84,8.57,38.24Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <path className="cls-2" d="M10.84,39.55l21.42-9.09L53,39.67l2.47-1.43-23-9.86L8.57,38.24Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <polygon className="cls-3"
                                     points="1.14 26.29 0 25.63 23.82 15.78 23.69 17.86 2.27 26.95 1.14 26.29"/>
                            <path className="cls-4" d="M23.52,36.4" transform="translate(-8.57 -12.6)"/>
                            <path className="cls-5" d="M32,36.06l23.43-13.6v2.65L32,38.65,8.57,24.7V22.46Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <path className="cls-6" d="M32.4,12.6l23,9.86L32,36.06,8.57,22.46Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <path className="cls-3" d="M32,37.37V36.06l23.43-13.6v2.65L32,38.65Z"
                                  transform="translate(-8.57 -12.6)"/>
                        </svg>
                    </div>
                    <div className="telnet-logo-name text-center">
                        <span>Telnet</span>
                    </div>

                    <div>

                    </div>
                    <div className="text-center my-2">
                                                 <span className="tanatec-footer text-center copyright ">Engineering Company Limited</span>

                        <p className=" mt-2"> <span className="footer-text copyright">&copy; {(new Date().getFullYear())}
                            <span> </span>All Rights Reserved</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
    )
}

export default Footer
