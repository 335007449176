import React from "react";
import NavigationSections from "./sections/Navigation"
import WelcomeSections from "./sections/Welcome"
import AboutSections from "./sections/About"
import ServicesSections from "./sections/Services"
import TeamSections from "./sections/Team"
import FooterSections from "./sections/Footer"
import Products from "./sections/Portfolio"
import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
function App() {
  return (
      <div className="full-page">
        <NavigationSections />
        <WelcomeSections />
        <AboutSections />
        <ServicesSections  />
        <Products/>
        <TeamSections   />
        <FooterSections />
        {/* <StickyTop/> */}
      </div>
  );
}

export default App;
