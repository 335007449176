import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import './owl.css';
function Welcome() {
    return (
  <div>
      <div className="container-fluid welcome px-0  " >


          {/* <svg   width="800" height="600"  id=" " data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 346.34 71.73">*/}


          {/*        <path id="top"  d="M0,0H286.18" stroke="#f11" fill="red" />*/}
          {/*        <path id="bottom"   d="10.42,193.94H422" stroke="#000" fill="blue"  />*/}
          {/*     /!*<circle cx="" cy="" r="8" fill="#529fd9">*!/*/}
          {/*     /!*    <animateMotion dur="9.6s" repeatCount="indefinite">*!/*/}
          {/*     /!*        <mpath href="#top"></mpath>*!/*/}
          {/*     /!*    </animateMotion>*!/*/}
          {/*     /!*</circle>*!/*/}
          {/*     /!*<circle cx="" cy="" r="8" fill="#431212">*!/*/}
          {/*     /!*    <animateMotion dur="9.6s" repeatCount="indefinite">*!/*/}
          {/*     /!*        <mpath href="#bottom"></mpath>*!/*/}
          {/*     /!*    </animateMotion>*!/*/}
          {/*     /!*</circle>*!/*/}
          {/* </svg>*/}

          {/*<svg width="800" height="600" xmlns="http://www.w3.org/2000/svg">*/}
          {/*    <path id="svg_8" d= "M231.3,311.8c-24.6-13.6-1.2-78.4-26.4-91-11.7-5.9-19.2,6.9-51.2,9.7-11.8,1.1-40.3,3.1-65-13.4-14.3-9.5-34.2-29.8-29.3-44.3,4.1-12,22.3-12,57-22.7,8.9-2.7,28.3-11,67-27.7,90.7-39.1,111.9-58.7,126.4-48s10.2,43,8.4,55.9c-4.5,32.8-20.1,40.9-14.9,58.8,6.8,23,36.5,21.4,39.8,40.6s-23.9,39.3-44.8,54.4C274.2,301.5,247.6,320.8,231.3,311.8Z" stroke="#000" fill="none"/>*/}
          {/*    <circle cx="" cy="" r="8" fill="#529fd9">*/}
          {/*        <animateMotion dur="6.6s" repeatCount="indefinite">*/}
          {/*            <mpath href="#svg_8"></mpath>*/}
          {/*        </animateMotion>*/}
          {/*    </circle>*/}
          {/*</svg>*/}
          {/*<svg xmlns="http://www.w3.org/2000/svg" version="1.1"*/}
          {/*     width="360" height="120"  viewBox="-10 10 360 55">*/}
          {/*    <path d="M-1,1.499h83c0,0,10.5,1,10.5,11.25s-10.25,12.25-10.25,12.25h-43.5c0,0-10.75,0.75-11.75,11.5s9.75,12.25,9.75,12.25H343.5"*/}
          {/*          stroke="#529fd9" stroke-width="2" fill="none" id="wire"></path>*/}
          {/*    <circle cx="" cy="" r="8" fill="#529fd9">*/}
          {/*        <animateMotion dur="9.6s" repeatCount="indefinite">*/}
          {/*            <mpath href="#wire"></mpath>*/}
          {/*        </animateMotion>*/}
          {/*    </circle>*/}
          {/*</svg>*/}
          <div className="row d-flex justify-content-center m-0">
              <div className="col-md-8 p-0">
                  <OwlCarousel items={1}
                               className="owl-theme"
                               loop
                               nav
                               autoplay ={true}
                               margin={8}
                               nav={false}
                  >
                      <div>
                          <img  className="img" src= {'images/telnet (1).jpg'} alt="Data and Voice Networks (LAN/WLAN)"/>
                          <div className="img-title">
                              Data and Voice Networks (LAN/WLAN)
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (1).png'} alt="                              Voice over IP (VoIP) Telephony
"/>
                          <div className="img-title">
                              Voice over IP (VoIP) Telephony
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (2).jpg'} alt="                              Media solution
" />
                          <div className="img-title">
                              Media solution
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (2).png'} alt="                              Security solutions and CCTV Cameras
" />
                          <div className="img-title">
                              Security solutions and CCTV Cameras
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (3).jpg'} alt="                              Video conferencing
" />
                          <div className="img-title">
                              Video conferencing
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (4).jpg'} alt="                              Automatic Boom Gates / Traffic and Parking systems
" />
                          <div className="img-title">
                              Automatic Boom Gates / Traffic and Parking systems
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (3).png'} alt="                              Door Access Control and Time Attendance Systems
" />
                          <div className="img-title">
                              Door Access Control and Time Attendance Systems
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (4).png'} alt="                              Fire Alarm and Fire Fighting/Suppression System
" />
                          <div className="img-title">
                              Fire Alarm and Fire Fighting/Suppression System
                          </div>
                      </div>
                      <div>
                          <img  className="img" src= {'images/telnet (7).jpg'} alt="                              Optical Fiber Installation
" />
                          <div className="img-title">
                              Optical Fiber Installation
                          </div>
                      </div>
                  </OwlCarousel>
              </div>
          </div>

      </div>
      <div>
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 100" style={{fill:"var(--light-bg)", position: "relative", }}>
              <path className="cls-1" d="M1366,278,0,192V178H1366Z" transform="translate(0 -178)"/>
          </svg>
      </div>
  </div>

    )
}

export default Welcome
