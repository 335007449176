import React from 'react'

function Services() {
    const serviceDetails = {
        "telecommunications":[
            "Microwave Links Installations and Commissioning",
            "BTS Installation and Commissioning",
            "GSM 900 & 1800, UMTS RF Installation and commissioning",
            "Telecommunications Network Deployment and Integration",
            "Radio Frequency (RF) Planning, Installation and Optimization of Radio Links",
            "Microwave Dish Installation and Commissioning",
            "Optic Fiber Network (OFC) Design, Installation and Commissioning for Both Indoor and Outdoor Environment",
            "Site Routers Installations, Configuration and Integration",
            "Rectifiers Installations and Troubleshooting"
        ],
        "network":[
            "Local Area Network (LAN) and WLAN Design and Integration",
            "Wide Area Network (WAN) Deployment and Integration",
            "Wireless Access Network Design, Installations and Optimization",
            "Telecommunications Network Deployment and Integration",
            "Radio Frequency (RF) Planning, Installation and Optimization of Radio Links",
            "Microwave Dish Installation and Commissioning",
            "Optic Fiber Network (OFC) Design, Installation and Commissioning for both Indoor and Outdoor Environment"

],
        "software":[
            "IP Network Infrastructure Configurations, Integration and Commissioning for Enterprise",
            "Network Security System Implementation and Troubleshooting",
            "Website Hosting and Domain Name Registration",
            "Mobile Applications Developments (Android Apps)",
            "Systems and Software Development for various Application",
            "Active Directory and DNS Configuration for Window Server Configurations",
            "Cloud Computing, Share Point and Office365 Configurations for Enterprise"
        ],
        "conference":[
            "Audio Visual Design and Installation for Hall, Theatres and Conference hall",
            "Audio Conferencing and Public Addressing systems for Schools, Hospitals, Malls, Churches, Mosque, Supermarkets etc.",
            "Video Conferencing solution for Enterprise and Government conferences",
            "Voice over IP (VoIP) Network Design, Installation and Configurations"

],
        "security":[
 	"Biometric Door Access Control Systems with Automatic SMS reports",
 	"Staff ID Printing with Door Access Privileges within an Organization",
 	"Capturing Finger prints for Integration with any Systems" ,
 	"Intruder Detections and Alarm Systems ",
 	"Automated Gate Controls Integrations with Access Control",
 	"Traffic Barriers/ Boom Gates Access Control System Integration",
 	"Turnstile Installation and Access Control Integration",
 	"IP Based CCTV Security Cameras Installation and Configuration",
 	"IP Based IP Intercom Systems and Telephony Systems which includes PABX and Other Gateways"
        ],

        "other":[
    "Fire Detection and Suppressions Systems Design, Installation and Servicing",
    "Fire Equipment and Extinguishers Servicing of all types",
    "GPS Car Tracking Systems Installation and Integration" ,
    "Electric fence Design, Installation and Configuration",
    "Satellite Dishes and Television Network Design and installation"

]

    };
    return (
        <div>
            <div id="services" className='full-height separator' >
                <div className="container separator py-5">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="row ">
                                <div className="col-md-12 pb-5">
                                    <h3 className="text-center section-header"><span
                                        className="section-number"> </span><span
                                        className="underline-header">Our Services</span></h3>
                                </div>
                                {/* Tabular View of Services */}
                                <div className="col-md-12">
                                    <div className="row ">
                                        <div className="col-md-4 py-4 text-md-left d-flex"  data-aos="slide-up" data-aos-delay="100" data-aos-once="true">
                                            <div className="d-flex flex-column service-tab">
                                                <div className="py-2 px-2 service-icon">
                                                    <div className="icon-bg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"
                                                             className="bi bi-window-sidebar" viewBox="0 0 16 16" style={{
                                                            fill: "url(#gradient_icon)"
                                                        }}>
                                                            <defs>
                                                                <linearGradient id="gradient_icon" x1="100%" y1="0%" x2="0%"
                                                                                y2="100%"
                                                                >
                                                                    <stop stopColor="var(--primary-top-right)" offset="0%"/>
                                                                    <stop stopColor="var(--primary-bottom-right)"
                                                                          offset="100%"/>
                                                                </linearGradient>
                                                            </defs>
                                                            <path
                                                                d="M0 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-5zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-8zm4-3a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-11z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="py-3 px-2">
                                                    <h4 className="service-header">
                                                        Telecommunication Systems Installation</h4>
                                                </div>
                                                <div className="py-2 px-2">
                                                    <div className="service-description ">
                                                        {serviceDetails["telecommunications"].map((details, index) => {
                                                                if (index < 3) {
                                                                    return <div className="d-flex justify-content-left py-1"
                                                                                key={details}>
                                                                        <div className="pr-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                                                                 height="16px"
                                                                                 className="bi bi-info-circle"
                                                                                 viewBox="0 0 16 16" style={{
                                                                                fill: "url(#gradient_icon)"
                                                                            }}>
                                                                                <defs>
                                                                                    <linearGradient id="gradient_icon" x1="100%"
                                                                                                    y1="0%" x2="0%" y2="100%"
                                                                                    >
                                                                                        <stop
                                                                                            stopColor="var(--primary-top-right)"
                                                                                            offset="0%"/>
                                                                                        <stop
                                                                                            stopColor="var(--primary-bottom-right)"
                                                                                            offset="100%"/>
                                                                                    </linearGradient>
                                                                                </defs>
                                                                                <path
                                                                                    d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                                            </svg>
                                                                        </div>
                                                                        <div>{details} </div>
                                                                    </div>
                                                                }
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="mt-auto text-right more px-2">{serviceDetails["telecommunications"].length > 3 &&
                                                <p>More</p>}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 py-4 text-md-left d-flex "  data-aos="slide-up"  data-aos-delay="200" data-aos-once="true">
                                     <div className=" d-flex flex-column service-tab">
                                         <div className="py-2 px-2 service-icon">
                                             <div className="icon-bg">
                                                 <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"
                                                      className="bi bi-phone" viewBox="0 0 16 16" style={{
                                                     fill: "url(#gradient_icon)"
                                                 }}>
                                                     <defs>
                                                         <linearGradient id="gradient_icon" x1="100%" y1="0%" x2="0%"
                                                                         y2="100%"
                                                         >
                                                             <stop stopColor="var(--primary-top-right)" offset="0%"/>
                                                             <stop stopColor="var(--primary-bottom-right)"
                                                                   offset="100%"/>
                                                         </linearGradient>
                                                     </defs>
                                                     <path
                                                         d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>


                                                 </svg>
                                             </div>


                                         </div>
                                         <div className="py-3 px-2">
                                             <h4 className="service-header">
                                                 Network and Hardware</h4>
                                         </div>
                                         <div className="py-2 px-2">
                                             <div className="service-description ">
                                                 {serviceDetails["network"].map((details, index) => {
                                                         if (index < 3) {
                                                             return <div className="d-flex justify-content-left py-1"
                                                                         key={details}>
                                                                 <div className="pr-2">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                                                          height="16px"
                                                                          className="bi bi-info-circle"
                                                                          viewBox="0 0 16 16" style={{
                                                                         fill: "url(#gradient_icon)"
                                                                     }}>
                                                                         <defs>
                                                                             <linearGradient id="gradient_icon" x1="100%"
                                                                                             y1="0%" x2="0%" y2="100%"
                                                                             >
                                                                                 <stop
                                                                                     stopColor="var(--primary-top-right)"
                                                                                     offset="0%"/>
                                                                                 <stop
                                                                                     stopColor="var(--primary-bottom-right)"
                                                                                     offset="100%"/>
                                                                             </linearGradient>
                                                                         </defs>
                                                                         <path
                                                                             d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                                     </svg>
                                                                 </div>
                                                                 <div>{details} </div>
                                                             </div>
                                                         }
                                                     }
                                                 )}
                                             </div>
                                         </div>
                                         <div
                                             className="mt-auto text-right more px-2">{serviceDetails["network"].length > 3 &&
                                         <p>More</p>}</div>
                                     </div>

                                        </div>
                                        <div className="col-md-4 py-4 text-md-left d-flex "  data-aos="slide-up"  data-aos-delay="300" data-aos-once="true">
                               <div className="d-flex flex-column service-tab">
                                   <div className="py-2 px-2 service-icon">
                                       <div className="icon-bg">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"
                                                className="bi bi-laptop" viewBox="0 0 16 16" style={{
                                               fill: "url(#gradient_icon)"
                                           }}>
                                               <defs>
                                                   <linearGradient id="gradient_icon" x1="100%" y1="0%" x2="0%"
                                                                   y2="100%"
                                                   >
                                                       <stop stopColor="var(--primary-top-right)" offset="0%"/>
                                                       <stop stopColor="var(--primary-bottom-right)"
                                                             offset="100%"/>
                                                   </linearGradient>
                                               </defs>
                                               <path
                                                   d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                               <path
                                                   d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12zM1 13V6h4v8H2a1 1 0 0 1-1-1zm5 1V6h9v7a1 1 0 0 1-1 1H6z"/>
                                           </svg>
                                       </div>
                                   </div>
                                   <div className="py-3 px-2">
                                       <h4 className="service-header">
                                           System and Software Development</h4>
                                   </div>
                                   <div className="py-2 px-2">
                                       <div className="service-description ">
                                           {serviceDetails["software"].map((details, index) => {
                                                   if (index < 3) {
                                                       return <div className="d-flex justify-content-left py-1"
                                                                   key={details}>
                                                           <div className="pr-2">
                                                               <svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                                                    height="16px"
                                                                    className="bi bi-info-circle"
                                                                    viewBox="0 0 16 16" style={{
                                                                   fill: "url(#gradient_icon)"
                                                               }}>
                                                                   <defs>
                                                                       <linearGradient id="gradient_icon" x1="100%"
                                                                                       y1="0%" x2="0%" y2="100%"
                                                                       >
                                                                           <stop
                                                                               stopColor="var(--primary-top-right)"
                                                                               offset="0%"/>
                                                                           <stop
                                                                               stopColor="var(--primary-bottom-right)"
                                                                               offset="100%"/>
                                                                       </linearGradient>
                                                                   </defs>
                                                                   <path
                                                                       d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                               </svg>
                                                           </div>
                                                           <div>{details} </div>
                                                       </div>
                                                   }
                                               }
                                           )}
                                       </div>
                                   </div>
                                   <div
                                       className="mt-auto text-right more px-2">{serviceDetails["software"].length > 3 &&
                                   <p>More</p>}</div>
                               </div>

                                        </div>
                                    </div>
                                    <div className="row py-4">
                                        <div className="col-md-4 py-4 text-md-left d-flex  "  data-aos="slide-up" data-aos-delay="400" data-aos-once="true">
                                             <div className=" d-flex flex-column service-tab">
                                            <div className="py-2 px-2 service-icon">
                                                <div className="icon-bg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"
                                                         className="bi bi-info-circle" viewBox="0 0 16 16" style={{
                                                        fill: "url(#gradient_icon)"
                                                    }}>
                                                        <defs>
                                                            <linearGradient id="gradient_icon" x1="100%" y1="0%" x2="0%"
                                                                            y2="100%"
                                                            >
                                                                <stop stopColor="var(--primary-top-right)" offset="0%"/>
                                                                <stop stopColor="var(--primary-bottom-right)"
                                                                      offset="100%"/>
                                                            </linearGradient>
                                                        </defs>
                                                        <path
                                                            d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                    </svg>


                                                </div>
                                            </div>
                                            <div className="py-3 px-2">
                                                <h4 className="service-header">
                                                    Conference Solution</h4>
                                            </div>
                                            <div className="py-2 px-2 ">
                                                <div className="service-description ">
                                                    {serviceDetails["conference"].map((details, index) => {
                                                            if (index < 3) {
                                                                return <div className="d-flex justify-content-left py-1"
                                                                            key={details}>
                                                                    <div className="pr-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                                                             height="16px"
                                                                             className="bi bi-info-circle"
                                                                             viewBox="0 0 16 16" style={{
                                                                            fill: "url(#gradient_icon)"
                                                                        }}>
                                                                            <defs>
                                                                                <linearGradient id="gradient_icon" x1="100%"
                                                                                                y1="0%" x2="0%" y2="100%"
                                                                                >
                                                                                    <stop
                                                                                        stopColor="var(--primary-top-right)"
                                                                                        offset="0%"/>
                                                                                    <stop
                                                                                        stopColor="var(--primary-bottom-right)"
                                                                                        offset="100%"/>
                                                                                </linearGradient>
                                                                            </defs>
                                                                            <path
                                                                                d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                                        </svg>
                                                                    </div>
                                                                    <div>{details} </div>
                                                                </div>
                                                            }
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="mt-auto text-right more px-2">{serviceDetails["conference"].length > 3 &&
                                            <p>More</p>}</div>
                                        </div>
                                        </div>
                                        <div className="col-md-4 py-4 text-md-left d-flex "  data-aos="slide-up"  data-aos-delay="500" data-aos-once="true">
                                             <div className="d-flex flex-column service-tab">
                                                 <div className="py-2 px-2 service-icon">
                                                     <div className="icon-bg">
                                                         <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"
                                                              className="bi bi-code-slash" viewBox="0 0 16 16" style={{
                                                             fill: "url(#gradient_icon)"
                                                         }}>
                                                             <defs>
                                                                 <linearGradient id="gradient_icon" x1="100%" y1="0%" x2="0%"
                                                                                 y2="100%"
                                                                 >
                                                                     <stop stopColor="var(--primary-top-right)" offset="0%"/>
                                                                     <stop stopColor="var(--primary-bottom-right)"
                                                                           offset="100%"/>
                                                                 </linearGradient>
                                                             </defs>
                                                             fill="currentColor" className="bi bi-shield-lock"
                                                             viewBox="0 0 16 16">
                                                             <path
                                                                 d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                                                             <path
                                                                 d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                         </svg>

                                                     </div>
                                                 </div>
                                                 <div className="py-3 px-2">
                                                     <h4 className="service-header">
                                                         Security Systems and Attendance Systems</h4>
                                                 </div>
                                                 <div className="py-2 px-2">
                                                     <div className="service-description ">
                                                         {serviceDetails["security"].map((details, index) => {
                                                                 if (index < 3) {
                                                                     return <div className="d-flex justify-content-left py-1"
                                                                                 key={details}>
                                                                         <div className="pr-2">
                                                                             <svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                                                                  height="16px"
                                                                                  className="bi bi-info-circle"
                                                                                  viewBox="0 0 16 16" style={{
                                                                                 fill: "url(#gradient_icon)"
                                                                             }}>
                                                                                 <defs>
                                                                                     <linearGradient id="gradient_icon" x1="100%"
                                                                                                     y1="0%" x2="0%" y2="100%"
                                                                                     >
                                                                                         <stop
                                                                                             stopColor="var(--primary-top-right)"
                                                                                             offset="0%"/>
                                                                                         <stop
                                                                                             stopColor="var(--primary-bottom-right)"
                                                                                             offset="100%"/>
                                                                                     </linearGradient>
                                                                                 </defs>
                                                                                 <path
                                                                                     d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                                             </svg>
                                                                         </div>
                                                                         <div>{details} </div>
                                                                     </div>
                                                                 }
                                                             }
                                                         )}
                                                     </div>
                                                 </div>
                                                 <div
                                                     className="mt-auto text-right more px-2">{serviceDetails["security"].length > 3 &&
                                                 <p>More</p>}</div>
                                             </div>
                                        </div>
                                        <div className="col-md-4 py-4 text-md-left d-flex "  data-aos="slide-up" data-aos-delay="600" data-aos-once="true">
                                            <div className="d-flex flex-column service-tab">
                                         <div className="py-2 px-2 service-icon">
                                             <div className="icon-bg">
                                                 <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem"
                                                      className="bi bi-window-sidebar" viewBox="0 0 16 16" style={{
                                                     fill: "url(#gradient_icon)"
                                                 }}>
                                                     <defs>
                                                         <linearGradient id="gradient_icon" x1="100%" y1="0%" x2="0%"
                                                                         y2="100%"
                                                         >
                                                             <stop stopColor="var(--primary-top-right)" offset="0%"/>
                                                             <stop stopColor="var(--primary-bottom-right)"
                                                                   offset="100%"/>
                                                         </linearGradient>
                                                     </defs>
                                                     <path
                                                         d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                                                 </svg>
                                             </div>

                                         </div>
                                         <div className="py-3 px-2">
                                             <h4 className="service-header">
                                                 Other Systems and Solutions</h4>
                                         </div>
                                         <div className="py-2 px-2 ">
                                             <div className="service-description  align-self-end ">
                                                 {serviceDetails["other"].map((details, index) => {
                                                         if (index < 3) {
                                                             return <div className="d-flex justify-content-left py-1"
                                                                         key={details}>
                                                                 <div className="pr-2">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                                                          height="16px"
                                                                          className="bi bi-info-circle"
                                                                          viewBox="0 0 16 16" style={{
                                                                         fill: "url(#gradient_icon)"
                                                                     }}>
                                                                         <defs>
                                                                             <linearGradient id="gradient_icon" x1="100%"
                                                                                             y1="0%" x2="0%" y2="100%"
                                                                             >
                                                                                 <stop
                                                                                     stopColor="var(--primary-top-right)"
                                                                                     offset="0%"/>
                                                                                 <stop
                                                                                     stopColor="var(--primary-bottom-right)"
                                                                                     offset="100%"/>
                                                                             </linearGradient>
                                                                         </defs>
                                                                         <path
                                                                             d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                                     </svg>
                                                                 </div>
                                                                 <div>{details} </div>
                                                             </div>
                                                         }
                                                     }
                                                 )}
                                             </div>
                                         </div>
                                         <div
                                             className="mt-auto text-right more px-2">{serviceDetails["other"].length > 3 &&
                                         <p>More</p>}</div>
                                     </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 100" style={{background:"var(--light-bg)",fill:"var(--default-bg)", position: "relative", }}>
                    <path className="cls-1" d="M1366,278,0,192V178H1366Z" transform="translate(0 -178)"/>
                </svg>
            </div>
        </div>
    )
}

export default Services
