import React from 'react'

function Team() {
    var teamDetails = [
        {
            "name":"Eng. James Lyimo",
            "position":"Managing Director",
        },        {
            "name":"Eng. Gati Nashon",
            "position":"Finance Director",
        },
        {
            "name":"Eng. Didas M. Mwaya",
            "position":"Chief Technical Officer",
        },
    ]
    return (
        <div id="team" className="separator"  style={{background:"var(--light-bg)"}}>
            <div className="container">
                <div className="row  py-5">
                    <div className="col-md-12 ">
                        <div className="py-3">
                            <h3 className="text-center section-header"><span className="section-number"></span><span className="underline-header">Team</span></h3>
                        </div>
                        {/* Tabular View of Services */}
                       <div className="d-flex justify-content-center">
                           <div className="col-md-10 py-4 ">
                               <div className="row d-flex justify-content-center py-2">
                                   {teamDetails.map((member)=>
                                       <div key={member.name}  className="col-md-4 p-1 team-tab"  data-aos="slide-up" data-aos-once="true">
                                           <div className="team-profile my-2 p-4">
                                               <div  data-aos="slide-up"  >
                                                   <div className=" team-profile-image" style={{
                                                       backgroundImage:  `url(${member.imgUrl})`,
                                                       backgroundPosition: 'center',
                                                       backgroundSize: 'cover',
                                                       backgroundRepeat: 'no-repeat'
                                                   }}>
                                                   </div>
                                               </div>
                                               <div className="team-col">
                                                   <div >
                                                       <p className="m-0 team-name">
                                                           {member.name}</p>
                                                   </div>
                                                   <div >
                                                       <p className="m-0 team-description">
                                                           {member.position}  </p>
                                                   </div>
                                               </div>
                                           </div>

                                       </div>
                                   )}
                               </div>

                           </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
