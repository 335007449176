import React  from 'react'
const Navigation = () => {
    return (
        <div id ="navigation_shadow"
             className="navigation nav-shadow sticky-top">
            <nav class="navbar container navbar-expand-lg  ">
                <div title="Telnet Engineering Company Limited" className="d-flex align-items-center">
                    <div  >
                        <svg id="Layer_1" className="logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                             height="2rem" viewBox="0 0 46.86 39.24">
                            <path className="cls-1" d="M32.4,28.38l23,9.86L32,51.84,8.57,38.24Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <path className="cls-2" d="M10.84,39.55l21.42-9.09L53,39.67l2.47-1.43-23-9.86L8.57,38.24Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <polygon className="cls-3"
                                     points="1.14 26.29 0 25.63 23.82 15.78 23.69 17.86 2.27 26.95 1.14 26.29"/>
                            <path className="cls-4" d="M23.52,36.4" transform="translate(-8.57 -12.6)"/>
                            <path className="cls-5" d="M32,36.06l23.43-13.6v2.65L32,38.65,8.57,24.7V22.46Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <path className="cls-6" d="M32.4,12.6l23,9.86L32,36.06,8.57,22.46Z"
                                  transform="translate(-8.57 -12.6)"/>
                            <path className="cls-3" d="M32,37.37V36.06l23.43-13.6v2.65L32,38.65Z"
                                  transform="translate(-8.57 -12.6)"/>
                        </svg>
                    </div>
                    <div className=" pl-3 telnet-logo-name">
                        <span>Telnet</span>
                    </div>
                </div>
                {/*<a class="navbar-brand tanatec-name" href="#"></a>*/}
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon">
         <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 16">
            <path d="M1,11H23a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1H0A1,1,0,0,1,1,11Z" transform="translate(0 -4)"/>
            <path d="M1,4H23a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H1A1,1,0,0,1,0,5H0A1,1,0,0,1,1,4Z" transform="translate(0 -4)"/>
             <path d="M1,18H23a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1H0A1,1,0,0,1,1,18Z" transform="translate(0 -4)"/>
        </svg>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item ">
                            <a href="#about" className="nav-link"><span className="link-number"></span>About</a>
                        </li>
                        <li class="nav-item ">
                            <a href="#services" className="nav-link"><span className="link-number"></span>Services</a>
                        </li>
                        <li className="nav-item ">
                            <a href="#products" className="nav-link"><span className="link-number"></span>Portfolio</a>
                        </li>
                        <li className="nav-item ">
                            <a href="#team" className="nav-link"><span className="link-number"></span>Team</a>
                        </li>
                        <li className="nav-item ">
                            <a href="#contact" className="nav-link"><span className="link-number"></span>Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )

}

export default Navigation
